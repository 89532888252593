// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-templates-bio-bio-js": () => import("./../../../src/templates/Bio/Bio.js" /* webpackChunkName: "component---src-templates-bio-bio-js" */),
  "component---src-templates-by-the-numbers-by-the-numbers-js": () => import("./../../../src/templates/ByTheNumbers/ByTheNumbers.js" /* webpackChunkName: "component---src-templates-by-the-numbers-by-the-numbers-js" */),
  "component---src-templates-by-the-numbers-recipe-detail-recipe-detail-js": () => import("./../../../src/templates/ByTheNumbers/RecipeDetail/RecipeDetail.js" /* webpackChunkName: "component---src-templates-by-the-numbers-recipe-detail-recipe-detail-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/Contact/Contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-homepage-homepage-js": () => import("./../../../src/templates/Homepage/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-homepage-js" */),
  "component---src-templates-spirit-and-mind-spirit-and-mind-js": () => import("./../../../src/templates/SpiritAndMind/SpiritAndMind.js" /* webpackChunkName: "component---src-templates-spirit-and-mind-spirit-and-mind-js" */),
  "component---src-templates-this-doc-makes-house-calls-this-doc-makes-house-calls-js": () => import("./../../../src/templates/ThisDocMakesHouseCalls/ThisDocMakesHouseCalls.js" /* webpackChunkName: "component---src-templates-this-doc-makes-house-calls-this-doc-makes-house-calls-js" */)
}

